export const types = {
  GET_MARKET_PRICE_SUCCESS: "GET_MARKET_PRICE_SUCCESS",
  GET_MARKET_PRICE_FAILED: "GET_MARKET_PRICE_FAILED",
  POST_SIGNUP_SUCCESS: "POST_SIGNUP_SUCCESS",
  POST_SIGNUP_FAILURE: "POST_SIGNUP_FAILURE",
  POST_SIGNIN_SUCCESS: "POST_SIGNIN_SUCCESS",
  POST_SIGNIN_FAILURE: "POST_SIGNIN_FAILURE",
  POST_CUSTOMERTOKEN_SUCCESS: "POST_CUSTOMERTOKEN_SUCCESS",
  POST_CUSTOMERTOKEN_FAILURE: "POST_CUSTOMERTOKEN_FAILURE",
  POST_EMAIL_LINK_SUCCESS:"POST_EMAIL_LINK_SUCCESS",
  POST_EMAIL_LINK_FAILURE:"POST_EMAIL_LINK_FAILURE",
  GET_DEPOSITADDRESS_SUCCESS: "GET_DEPOSITADDRESS_SUCCESS",
  GET_DEPOSITADDRESS_FAILED: "GET_DEPOSITADDRESS_FAILED",
  GET_DEPOSITADDRESS_GUID_SUCCESS: "GET_DEPOSITADDRESS_GUID_SUCCESS",
  GET_DEPOSITADDRESS_GUID_FAILED: "GET_DEPOSITADDRESS_GUID_FAILED",
  POST_DEPOSITADDRESS_SUCCESS: "POST_DEPOSITADDRESS_SUCCESS",
  POST_DEPOSITADDRESS_FAILED: "POST_DEPOSITADDRESS_FAILED",
  GET_BTC_PRICE_SUCCESS: "GET_BTC_PRICE_SUCCESS",
  GET_BTC_PRICE_FAILED: "GET_BTC_PRICE_FAILED",
  GET_ETH_PRICE_SUCCESS: "GET_ETH_PRICE_SUCCESS",  
  GET_ETH_PRICE_FAILED: "GET_ETH_PRICE_FAILED",
  GET_USDC_PRICE_SUCCESS: "GET_USDC_PRICE_SUCCESS",
  GET_USDC_PRICE_FAILED:"GET_USDC_PRICE_FAILED",
  GET_LTC_PRICE_SUCCESS:"GET_LTC_PRICE_SUCCESS",
  GET_LTC_PRICE_FAILED:"GET_LTC_PRICE_FAILED",
  GET_BCH_PRICE_SUCCESS:"GET_BCH_PRICE_SUCCESS",
  GET_BCH_PRICE_FAILED:"GET_BCH_PRICE_FAILED",
  GET_BTC_PRICE_FOR_FIVE_YEARS_SUCCESS: "GET_BTC_PRICE_FOR_FIVE_YEARS_SUCCESS",
  GET_BTC_PRICE_FOR_FIVE_YEARS_FAILED: "GET_BTC_PRICE_FOR_FIVE_YEARS_FAILED",
  GET_ETH_PRICE_FOR_FIVE_YEARS_SUCCESS: "GET_ETH_PRICE_FOR_FIVE_YEARS_SUCCESS",
  GET_ETH_PRICE_FOR_FIVE_YEARS_FAILED: "GET_ETH_PRICE_FOR_FIVE_YEARS_FAILED",
  GET_USDC_PRICE_FOR_FIVE_YEARS_SUCCESS: "GET_USDC_PRICE_FOR_FIVE_YEARS_SUCCESS",
  GET_USDC_PRICE_FOR_FIVE_YEARS_FAILED: "GET_USDC_PRICE_FOR_FIVE_YEARS_FAILED",
  GET_LTC_PRICE_FOR_FIVE_YEARS_SUCCESS: "GET_LTC_PRICE_FOR_FIVE_YEARS_SUCCESS",
  GET_LTC_PRICE_FOR_FIVE_YEARS_FAILED: "GET_LTC_PRICE_FOR_FIVE_YEARS_FAILED",
  GET_BCH_PRICE_FOR_FIVE_YEARS_SUCCESS: "GET_BCH_PRICE_FOR_FIVE_YEARS_SUCCESS",
  GET_BCH_PRICE_FOR_FIVE_YEARS_FAILED: "GET_BCH_PRICE_FOR_FIVE_YEARS_FAILED",
  GET_XRP_PRICE_FOR_FIVE_YEARS_SUCCESS: "GET_XRP_PRICE_FOR_FIVE_YEARS_SUCCESS",
  GET_XRP_PRICE_FOR_FIVE_YEARS_FAILED: "GET_XRP_PRICE_FOR_FIVE_YEARS_FAILED",
  GET_CRYPTO_MARKET_DATA_SUCCESS: "GET_CRYPTO_MARKET_DATA_SUCCESS",
  GET_CRYPTO_MARKET_DATA_FAILED: "GET_CRYPTO_MARKET_DATA_FAILED",
  GET_CUSTOMER_SUCCESS: "GET_CUSTOMER_SUCCESS",
  GET_CUSTOMER_FAILED: "GET_CUSTOMER_FAILED",
  POST_QUOTE_SUCCESS: "POST_QUOTE_SUCCESS",
  POST_QUOTE_FAILED: "POST_QUOTE_FAILED",
  POST_TRANSFER_SUCCESS: "POST_TRANSFER_SUCCESS",
  POST_TRANSFER_FAILED: "POST_TRANSFER_FAILED",
  GET_WORKFLOW_SUCCESS: "GET_WORKFLOW_SUCCESS",
  GET_WORKFLOW_FAILED: "GET_WORKFLOW_FAILED",
  GET_TRANSFERS_SUCCESS: "GET_TRANSFERS_SUCCESS",
  GET_TRANSFERS_FAILED: "GET_TRANSFERS_FAILED",
  GET_TRADES_SUCCESS : "GET_TRADES_SUCCESS",
  GET_TRADES_FAILED : "GET_TRADES_FAILED",
  POST_WORKFLOWS_SUCCESS: "POST_WORKFLOWS_SUCCESS",
  POST_WORKFLOWS_FAILED: "POST_WORKFLOWS_FAILED",
  POST_EXTERNAL_BANK_ACCCOUNT_SUCCESS: "POST_EXTERNAL_BANK_ACCCOUNT_SUCCESS",
  POST_EXTERNAL_BANK_ACCCOUNT_FAILED: "POST_EXTERNAL_BANK_ACCCOUNT_FAILED",
  PATCH_EXTERNAL_BANK_ACCCOUNT_SUCCESS:"PATCH_EXTERNAL_BANK_ACCCOUNT_SUCCESS",
  PATCH_EXTERNAL_BANK_ACCCOUNT_FAILED:"PATCH_EXTERNAL_BANK_ACCCOUNT_FAILED",
  GET_EXTERNAL_BANK_ACCCOUNT_LIST_SUCCESS: "GET_EXTERNAL_BANK_ACCCOUNT_LIST_SUCCESS",
  GET_EXTERNAL_BANK_ACCCOUNT_LIST_FAILED: "GET_EXTERNAL_BANK_ACCCOUNT_LIST_FAILED",
  DELETE_EXTERNAL_BANK_ACCCOUNT_SUCCESS: "DELETE_EXTERNAL_BANK_ACCCOUNT_SUCCESS",
  DELETE_EXTERNAL_BANK_ACCCOUNT_FAILED: "DELETE_EXTERNAL_BANK_ACCCOUNT_FAILED",
  DELETE_EXTERNAL_BANK_ACCCOUNT_WALLET_SUCCESS:"DELETE_EXTERNAL_BANK_ACCCOUNT_WALLET_SUCCESS",
  DELETE_EXTERNAL_BANK_ACCCOUNT_WALLET_FAILED:"DELETE_EXTERNAL_BANK_ACCCOUNT_WALLET_FAILED",
  GET_EXTERNAL_BANK_ACCCOUNT_WALLET_LIST_SUCCESS: "GET_EXTERNAL_BANK_ACCCOUNT_WALLET_LIST_SUCCESS",
  GET_EXTERNAL_BANK_ACCCOUNT_WALLET_LIST_FAILED: "GET_EXTERNAL_BANK_ACCCOUNT_WALLET_LIST_FAILED",
  POST_EXTERNAL_BANK_ACCCOUNT_WALLET_SUCCESS: "POST_EXTERNAL_BANK_ACCCOUNT_WALLET_SUCCESS",
  POST_EXTERNAL_BANK_ACCCOUNT_WALLET_FAILED: "POST_EXTERNAL_BANK_ACCCOUNT_WALLET_FAILED",
  POST_TRADES_SUCCESS: "POST_TRADES_SUCCESS",
  POST_TRADES_FAILED: "POST_TRADES_FAILED",
  GET_LANDING_PAGE_PRICE_SUCCESS: "GET_LANDING_PAGE_PRICE_SUCCESS",
  GET_LANDING_PAGE_PRICE_FAILDED: "GET_LANDING_PAGE_PRICE_FAILDED",
  COMMON_ERROR_MESSAGE : 'COMMON_ERROR_MESSAGE',
  COMMON_SUCCESS_MESSAGE : 'COMMON_SUCCESS_MESSAGE',
  GET_CUSTOMER_ACCOUNT_DETAILS_SUCCESS:"GET_CUSTOMER_ACCOUNT_DETAILS_SUCCESS",
  GET_CUSTOMER_ACCOUNT_DETAILS_FAILED:"GET_CUSTOMER_ACCOUNT_DETAILS_FAILED",
  POST_IDENTITY_VERIFICATION_SUCCESS: "POST_IDENTITY_VERIFICATION_SUCCESS",
  POST_IDENTITY_VERIFICATION_FAILED: "POST_IDENTITY_VERIFICATION_FAILED",
  GET_IDENTITY_VERIFICATION_SUCCESS: "GET_IDENTITY_VERIFICATION_SUCCESS",
  GET_IDENTITY_VERIFICATION_FAILED: "GET_IDENTITY_VERIFICATION_FAILED",
  LOGOUT: "LOGOUT",
  UPDATE_THEME:"UPDATE_THEME",
  POST_PASSWORD_RESET_LINK_SUCCESS:"POST_PASSWORD_RESET_LINK_SUCCESS",
  POST_PASSWORD_RESET_LINK_FAILED:"POST_PASSWORD_RESET_LINK_FAILED",
  CREATE_OTP_SUCCESS:"CREATE_OTP_SUCCESS",
  CREATE_OTP_FAILED:"CREATE_OTP_FAILED",
  VERIFY_OTP_SUCCESS:"VERIFY_OTP_SUCCESS",
  VERIFY_OTP_FAILED:"VERIFY_OTP_FAILED",
  PASSWORD_UPDATE_SUCCESS:"PASSWORD_UPDATE_SUCCESS",
  PASSWORD_UPDATE_FAILED:"PASSWORD_UPDATE_FAILED",
  USER_DETAIL_SUCCESS:"USER_DETAIL_SUCCESS",
  USER_DETAIL_FAILED:"USER_DETAIL_FAILED",
  EMAIL_NOTIFICATION_SUCCESS:"EMAIL_NOTIFICATION_SUCCESS",
  EMAIL_NOTIFICATION_FAILED:"EMAIL_NOTIFICATION_FAILED",
  REGENERATE_CUSTOMER_TOKEN_SUCCESS:"REGENERATE_CUSTOMER_TOKEN_SUCCESS",
  REGENERATE_CUSTOMER_TOKEN_FAILED:"REGENERATE_CUSTOMER_TOKEN_FAILED",
  CREATE_OTP_CRYPTO_SUCCESS:"CREATE_OTP_CRYPTO_SUCCESS",
  CREATE_OTP_CRYPTO_FAILED:"CREATE_OTP_CRYPTO_FAILED",
  VERIFY_OTP_CRYPTO_SUCCESS:"VERIFY_OTP_CRYPTO_SUCCESS",
  VERIFY_OTP_CRYPTO_FAILED:"VERIFY_OTP_CRYPTO_FAILED",
  GET_IDENTITY_VERIFICATION_LIST_SUCCESS:"GET_IDENTITY_VERIFICATION_LIST_SUCCESS",
  GET_IDENTITY_VERIFICATION_LIST_FAILED:"GET_IDENTITY_VERIFICATION_LIST_FAILED",
  POST_WORK_FLOW_PAYMENT_SUCCESS:"POST_WORK_FLOW_PAYMENT_SUCCESS",
  POST_WORK_FLOW_PAYMENT_FAILED:"POST_WORK_FLOW_PAYMENT_FAILED",
  GET_WORK_FLOW_PAYMENT_SUCCESS:"GET_WORK_FLOW_PAYMENT_SUCCESS",
  GET_WORK_FLOW_PAYMENT_FAILED:"GET_WORK_FLOW_PAYMENT_FAILED",
  CHECK_WALLET_SUCCESS:"CHECK_WALLET_SUCCESS",
  CHECK_WALLET_FAILED:"CHECK_WALLET_FAILED",
  GENERATE_CUSTOMER_ACCOUNT_SUCCESS:"GENERATE_CUSTOMER_ACCOUNT_SUCCESS",
  GENERATE_CUSTOMER_ACCOUNT_FAILED:"GENERATE_CUSTOMER_ACCOUNT_FAILED",
  GET_SINGLE_EXTERNAL_BANK_ACCCOUNT_SUCCESS:"GET_SINGLE_EXTERNAL_BANK_ACCCOUNT_SUCCESS",
  GET_SINGLE_EXTERNAL_BANK_ACCCOUNT_FAILED:"GET_SINGLE_EXTERNAL_BANK_ACCCOUNT_FAILED",
  PB_GET_SINGLE_EXTERNAL_BANK_ACCCOUNT_SUCCESS:"PB_GET_SINGLE_EXTERNAL_BANK_ACCCOUNT_SUCCESS",
  PB_GET_SINGLE_EXTERNAL_BANK_ACCCOUNT_FAILED:"PB_GET_SINGLE_EXTERNAL_BANK_ACCCOUNT_FAILED",
  PB_POST_EXTERNAL_BANK_ACCCOUNT_SUCCESS:"PB_POST_EXTERNAL_BANK_ACCCOUNT_SUCCESS",
  PB_POST_EXTERNAL_BANK_ACCCOUNT_FAILED:"PB_POST_EXTERNAL_BANK_ACCCOUNT_FAILED",
  POST_CONTACT_FORM_DATA_SUCCESS:"POST_CONTACT_FORM_DATA_SUCCESS",
  POST_CONTACT_FORM_DATA_FAILED:"POST_CONTACT_FORM_DATA_FAILED",
};
