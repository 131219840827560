import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import './css/style.css';
// import './css/style-two.css';
// import './css/tce.css';
import Index from './jsx';
import Notification from './jsx/layout/Notification';
// import { Lines } from 'react-preloaders';
import {useSelector} from "react-redux";
// import ReactGA from 'react-ga4';

// if (process.env.REACT_APP_ENV === 'prod') {
//   ReactGA.initialize('G-2PFL8XG3CT');
// }

function App() {
  const themeValue = useSelector((state) => state.authReducer.themeValue);
  return (
    <div  className={`${themeValue === '1' ? 'dark-theme App' : 'App'}`}>
      <Notification/>
      <Index />
    </div>
  );
}

export default App;
