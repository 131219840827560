import React,{Suspense} from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import  { ProtectedRoute,AuthenticateRoute } from "./ProtectedRoute";
import {AdminAuthenticateRoute, AdminRoute} from "./pages/admin/AdminRoute";
import GifloaderMini from "./layout/gifloadermini"

const Accounts = React.lazy(() => import('./pages/accounts'));
const BuySell = React.lazy(() => import('./pages/buy-sell'));
const PaymentMethod = React.lazy(() => import('./pages/payment-method'));
const Dashboard = React.lazy(() => import('./pages/index'));
const Landing = React.lazy(() => import('./pages/landing'));
const PrivacyAgreement = React.lazy(() => import('./pages/privacy-agreement'));
const PrivacyPolicy = React.lazy(() => import('./pages/privacy-policy'));
const Reset = React.lazy(() => import('./pages/reset'));
const Settings = React.lazy(() => import('./pages/settings'));
const Preferences = React.lazy(() => import('./pages/settings-preferences'));
const Signin = React.lazy(() => import('./pages/signin'));
const Signup = React.lazy(() => import('./pages/signup'));
const Deposits = React.lazy(() => import('./pages/deposits'));
const Withdrawals = React.lazy(() => import('./pages/withdrawals'));
const Transactions = React.lazy(() => import('./pages/transactions'));
const PageNotFound = React.lazy(() => import('./pages/page-not-found'));
const About = React.lazy(() => import('./pages/about'));
const ReferralProgram = React.lazy(() => import('./pages/referral-program'));
const Wallet = React.lazy(() => import('./pages/wallet'));
const Gettingstarted = React.lazy(() => import('./pages/getting-started'));
const Learn = React.lazy(() => import('./pages/learn'));
const Services = React.lazy(() => import('./pages/services'));
const ExternalWallet = React.lazy(() => import('./pages/external-wallet'));
const Logout = React.lazy(() => import('./shared/Logout'));
const InstantBuySellandWithdraw = React.lazy(() => import('./pages/Instant-Buy-Sell-and-Withdraw'));
const RealTimeQuotes = React.lazy(() => import('./pages/Real-Time-Quotes'));
const NonCustodial = React.lazy(() => import('./pages/Non-Custodial'));
const SafeandSecureCryptoWallet = React.lazy(() => import('./pages/Safe-and-Secure-Crypto-Wallet'));
const AdminLogin = React.lazy(() => import('./pages/admin/AdminLogin'));
const CustomerList = React.lazy(() => import('./pages/admin/CustomerList'));
const CustomerDetails = React.lazy(() => import('./pages/admin/CustomerDetails'));
const Trades = React.lazy(() => import('./pages/admin/Trades'));
const CustomerAccounts = React.lazy(() => import('./pages/admin/CustomerAccounts'));
const Sales = React.lazy(() => import('./pages/admin/Sales'));
const Faq = React.lazy(() => import('./pages/faq'));
const UserAgreement = React.lazy(() => import('./pages/user-agreement'));
const ResetPassword = React.lazy(() => import('./pages/reset-password'));
const Otc = React.lazy(() => import('./pages/otc'));
const Contact = React.lazy(() => import('./pages/contact'));
const WIIBitcoin = React.lazy(() => import('./pages/whatItIs/Bitcoin'));
const WIILitecoin = React.lazy(() => import('./pages/whatItIs/Litecoin'));
const WIIBitcoinCash = React.lazy(() => import('./pages/whatItIs/BitcoinCash'));
const WIIXRP = React.lazy(() => import('./pages/whatItIs/XRP'));
const WIIEther = React.lazy(() => import('./pages/whatItIs/Ether'));
const WIIStellarLumensXLM = React.lazy(() => import('./pages/whatItIs/StellarLumensXLM'));
const WIIDogeCoin = React.lazy(() => import('./pages/whatItIs/DogeCoin'));
const WIIcardano = React.lazy(() => import('./pages/whatItIs/Cardano'));
const WIIChainlink = React.lazy(() => import('./pages/whatItIs/Chainlink'));
const WIIPolkadot = React.lazy(() => import('./pages/whatItIs/Polkadot'));
const WIIBlockchain = React.lazy(() => import('./pages/whatItIs/Blockchain'));
const WIIDecentralizedFinance = React.lazy(() => import('./pages/whatItIs/DecentralizedFinance'));
const HTBBitcoin = React.lazy(() => import('./pages/howToBuy/Bitcoin'));
const HTBLitecoin = React.lazy(() => import('./pages/howToBuy/Litecoin'));
const HTBEther = React.lazy(() => import('./pages/howToBuy/Ether'));
const HTBBitcoinCash = React.lazy(() => import('./pages/howToBuy/BitcoinCash'));
const HTBXRP = React.lazy(() => import('./pages/howToBuy/XRP'));
const HTBStellarLumens = React.lazy(() => import('./pages/howToBuy/StellarLumensXLM'));
const HTBDogeCoin = React.lazy(() => import('./pages/howToBuy/DogeCoin'));
const HTBCardano = React.lazy(() => import('./pages/howToBuy/Cardano'));
const HTBChainlink = React.lazy(() => import('./pages/howToBuy/Chainlink'));
const HTBPolkadot = React.lazy(() => import('./pages/howToBuy/Polkadot'));
const Windsor = React.lazy(() => import('./pages/static/serviceArea/Windsor'));


function Index() {
  return (
    <>
      <BrowserRouter>
        <div id="main-wrapper">
        <Suspense fallback={<div className="vh-100 d-flex align-items-center justify-content-center"> <GifloaderMini /> </div>}> 
          <Routes>
            <Route 
              path="/admin/signin"
              element={
                <AdminAuthenticateRoute>
                  <AdminLogin/>
                </AdminAuthenticateRoute>
              }
            />
            
            <Route 
              path="/admin/customer"
              element={
                <AdminRoute>
                  <CustomerList/>
                </AdminRoute>
              }
            />

            <Route 
              path="/admin/customer/:guid"
              element={
                <AdminRoute>
                  <CustomerDetails/>
                </AdminRoute>
              }
            />

            <Route 
              path="/admin/trades"
              element={
                <AdminRoute>
                  <Trades/>
                </AdminRoute>
              }
            />
            <Route 
              path="/admin/customer-accounts"
              element={
                <AdminRoute>
                  <CustomerAccounts/>
                </AdminRoute>
              }
            />
            <Route 
              path="/admin/sales"
              element={
                <AdminRoute>
                  <Sales/>
                </AdminRoute>
              }
            />
            
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />

            <Route
              path="/exchange"
              element={
                <ProtectedRoute>
                  <BuySell />
                </ProtectedRoute>
              }
            />
            <Route
              path="/assets"
              element={
                <ProtectedRoute>
                  <Accounts />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <ProtectedRoute>
                  <Settings />
                </ProtectedRoute>
              }
            />
            <Route path="/" element={<Landing />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-and-conditions" element={<PrivacyAgreement />} />
            <Route path="/user-agreement" element={<UserAgreement />} />
            <Route path="/getting-started" element={<Gettingstarted />} />

            <Route
              path="/withdrawals"
              element={
                <ProtectedRoute>
                  <Withdrawals />
                </ProtectedRoute>
              }
            />
            <Route
              path="/orders"
              element={
                <ProtectedRoute>
                  <Transactions />
                </ProtectedRoute>
              }
            />

            <Route
              path="/deposits"
              element={
                <ProtectedRoute>
                  <Deposits />
                </ProtectedRoute>
              }
            />

            <Route
              path="/payment-method"
              element={
                <ProtectedRoute>
                  <PaymentMethod />
                </ProtectedRoute>
              }
            />

            <Route
              path="/external-wallet"
              element={
                <ProtectedRoute>
                  <ExternalWallet />
                </ProtectedRoute>
              }
            />

            <Route 
              path="/settings-preferences" 
              element={
                <ProtectedRoute>
                  <Preferences />
                </ProtectedRoute>
              }
            />            
            <Route
              path="/signin"
              element={
                <AuthenticateRoute>
                  <Signin />
                </AuthenticateRoute>
              }
            />

            <Route path="/signup" element={
              <AuthenticateRoute>
              <Signup />
            </AuthenticateRoute>
            } />
            <Route path="/reset" element={<Reset />} />
            <Route path="/reset-password" element={<ResetPassword/>} />
            <Route path="/buy-crypto-in-windsor" element={<Windsor/>} />
            <Route path="/about" element={<About/>} />
            <Route path="/referral-program" element={<ReferralProgram/>} />
            <Route path="/wallet" element={<Wallet/>} />
            <Route path="/learn" element={<Learn/>} />
            <Route path="/faq" element={<Faq/>} />
            <Route path="/services" element={<Services/>} />
            <Route path="/otc" element={<Otc/>} />
            <Route path="*" element={<PageNotFound />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/Instant-Buy-Sell-and-Withdraw" element={<InstantBuySellandWithdraw />} />
            <Route path="/Real-Time-Quotes" element={<RealTimeQuotes />} />
            <Route path="/Non-Custodial" element={<NonCustodial />} />
            <Route path="/Safe-and-Secure-Crypto-Wallet" element={<SafeandSecureCryptoWallet />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/what-is-bitcoin-btc" element={<WIIBitcoin />} />
            <Route path="/what-is-ether" element={<WIIEther />} />
            <Route path="/what-is-litecoin" element={<WIILitecoin />} />
            <Route path="/what-is-bitcoin-cash" element={<WIIBitcoinCash />} />
            <Route path="/what-is-XRP" element={<WIIXRP />} />            
            <Route path="/what-is-stellar" element={<WIIStellarLumensXLM />} />
            <Route path="/what-is-dogecoin" element={<WIIDogeCoin />} />
            <Route path="/what-is-cardano" element={<WIIcardano />} />
            <Route path="/what-is-chainlink" element={<WIIChainlink />} />
            <Route path="/what-is-polkadot" element={<WIIPolkadot />} />
            <Route path="/What-is-a-blockchain" element={<WIIBlockchain />} />
            <Route path="/decentralized-finance-defi-wallet" element={<WIIDecentralizedFinance />} />
            <Route path="/how-to-buy-bitcoin-canada" element={<HTBBitcoin />} />
            <Route path="/how-to-buy-ether" element={<HTBEther />} />
            <Route path="/how-to-buy-litecoin" element={<HTBLitecoin />} />
            <Route path="/how-to-buy-bitcoin-cash" element={<HTBBitcoinCash />} />
            <Route path="/how-to-buy-XRP" element={<HTBXRP />} />
            <Route path="/how-to-buy-stellar" element={<HTBStellarLumens />} />
            <Route path="/how-to-buy-dogecoin" element={<HTBDogeCoin />} />
            <Route path="/how-to-buy-cardano" element={<HTBCardano />} />
            <Route path="/how-to-buy-chainlink" element={<HTBChainlink />} />
            <Route path="/how-to-buy-polkadot" element={<HTBPolkadot />} />
          </Routes>
          </Suspense>
        </div>
      </BrowserRouter>
    </>
  );
}

export default Index;
